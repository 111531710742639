/**
 * Giving IE 11 all the things it lacks.
 *
 * Babel 7 is supposed to support automatic polyfilling which might be a better solution.
 * https://babeljs.io/blog/2018/08/27/7.0.0#automatic-polyfilling-experimental
 */
import 'core-js/features/array/find';
import 'core-js/features/array/from';
import 'core-js/features/array/for-each';
import 'core-js/features/array/includes';
import 'core-js/features/array/virtual/find-index';
import 'core-js/features/number/is-nan';
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/object/values';
import 'core-js/features/object/keys';
import 'core-js/features/string/ends-with';
import 'core-js/features/string/starts-with';
import 'core-js/features/string/includes';

/**
 * Import more locale data here as the app grows and needs to support more locales.
 *
 * Doing it this way the locale data is included in our script bundles and served to all users.
 *
 * This is fine for now because we only need to support a couple locales. If this list of supported locales
 * grows very large we'll probably only want to send locale data to users on an as needed basis.
 *
 * There are other options like using polyfill.io:
 * https://github.com/andyearnshaw/Intl.js/tree/v1.2.5#intljs-and-ft-polyfill-service
 */
// import IntlPolyfill from 'intl';
// import 'intl/locale-data/jsonp/en-CA';

// import 'intl/locale-data/jsonp/fr-CA';
// import 'intl/locale-data/jsonp/en-US';
// import 'intl/locale-data/jsonp/en-AU';

/**
 * HTMLElement.scrollTo is not supported in IE11 or Edge. We use this on the GuestInformation screen to ensure that
 * as a user navigates guests via the EditGuest modals/panel, the ScrollArea updates accordingly. SmoothSroll polyfills
 * that functionality, along with the rest of the scroll-behavior spec.
 *
 * See: https://github.com/iamdustan/smoothscroll/blob/master/src/smoothscroll.js
 * Also: https://developer.mozilla.org/en/docs/Web/CSS/scroll-behavior
 */
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

/**
 * react-intl is being used for localization.
 *
 * In browsers without Intl everything is polyfilled.
 *
 * DateTimeFormat and NumberFormat are not consistent across browsers so we are using the polyfill
 * to produce a consistent result.
 */
// if (typeof window.Intl === 'undefined') {
//   window.Intl = IntlPolyfill;
// } else {
//   window.Intl.NumberFormat = IntlPolyfill.NumberFormat;
//   window.Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
// }

// https://github.com/miguelcobain/ember-paper/issues/1058
// Add this to fix some issues on the map page where people were using
// query select all and then iteration with forEach.
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
